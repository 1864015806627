import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'

import Layout from '../components/Layout'
import Img from '../components/AtomImg'
import Div from './../components/Div'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import HDBRCotidiano from '../images/atendimento/HDBR-Cotidiano-1.jpg'
import HDBRCotidiano2 from '../images/atendimento/HDBR-Cotidiano-2.jpg'
import HDBRCotidiano3 from '../images/atendimento/HDBR-Cotidiano-3.jpg'
import HDBRCotidiano4 from '../images/atendimento/HDBR-Cotidiano-4.jpg'
import HDBRCotidiano5 from '../images/atendimento/HDBR-Cotidiano-5.jpg'
import HDBRCotidiano6 from '../images/atendimento/HDBR-Cotidiano-6.jpg'

import { Container } from '../components/styles/Layout.styles'
import Button from '../components/Button/Button'
import { 
  styledNamePage,
  header,
  imgSmall,
  imgMedium
} from '../components/styles/OurService.styles'


export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledNamePage}>
      <Container>
        <Row
          flexDirection={['column']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <H4>
              <span>POR QUE A HOSTDIME?</span>
              Nosso Atendimento
            </H4>
            <Div
              maxWidth={['90%', '60%', 300, 320]}
              style={{
                margin: '0 auto'
              }}
            >
              <Img
                fluid={data.image1.childImageSharp.fluid}
                loading='eager'
              />
            </Div>
            <H4 style={{ textAlign: 'center' }}>24x7x365</H4>
            <Paragraph>Atendimento personalizado com consultores de soluções dedicados a desenvolver uma combinação de recursos exclusiva, de acordo com a sua necessidade.</Paragraph>
            <Row
              mt={[40]}
            >
              <Div
                style={{
                  width: '100%',
                  maxWidth: 1000,
                  margin: '0 auto'
                }}
              >
                <img style={{ width: '100%', height: 'auto' }} src={HDBRCotidiano4} />
              </Div>
            </Row>
            <Row
              mt={[4, 4, 8, 8]}
            >
              <Div
                style={{
                  width: '100%',
                  maxWidth: 1000,
                  margin: '0 auto',
                  display: 'inline-block'
                }}
              >
                <img css={imgSmall} src={HDBRCotidiano2} />
                <img css={imgSmall} src={HDBRCotidiano3} />
                <img css={imgSmall} src={HDBRCotidiano} />
              </Div>
            </Row>
            <Row
              mt={[0, 0, 6, 6]}
              mb={[40]}
            >
              <Div
                style={{
                  width: '100%',
                  maxWidth: 1000,
                  margin: '0 auto',
                  display: 'inline-block'
                }}
              >
                <img css={imgMedium} src={HDBRCotidiano5} />
                <img css={imgMedium} src={HDBRCotidiano6} />
              </Div>
            </Row>
            <Paragraph>Equipe de suporte especializada e multicultural disponível 24x7x365 para uma assistência eficaz e assessoria completa na implementação de sistemas.</Paragraph>
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <Button
                onClick={() => navigate('/suporte')}
                schema='secondary'
                color='white'
                variant='highEmphasis'
                width={['100%']}
                maxWidth={['200px']}
                style={{
                  display: 'flex',
                  border: 'none',
                  margin: '0 auto',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                }}
              >
                NOSSO SUPORTE
              </Button>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Nosso Atendimento' />
      <PageTemplate
        title='Nosso Atendimento'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "atendimento/nosso-atendimento.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default StaticPage
