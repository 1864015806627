import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledNamePageBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [70]
  })
)

export const styledNamePage = css`
  ${styledNamePageBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['center']
  })
)

export const PHeaderBps = css(
  mq({
    maxwidth: [328, '100%', 422, 422],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['center']
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  p {
    ${PHeaderBps};
  }
`

export const imgSmall = css(
  mq({
    width: ['100%', '100%', '33.3%', '33.3%']
  })
)

export const imgMedium = css(
  mq({
    width: ['100%', '100%', '50%', '50%']
  })
)